import React from 'react';

import Layout from '../../components/Layout';

import '../../assets/styles/pages/privacy-poilicy.scss';

const Terminos = () => (
  <Layout
    title="Términos y Condiciones | ELE Barcelona"
    description="Política de pago, cancelación y respeto al curso. Normas de buena conducta de comportamiento. Información de la disponibilidad certificado ELE Barcelona."
  >
    <div className="privacy-policy privacy-policy--extended">
      <section className="first-section content">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <h1 className="c-title-34 title">Términos y condiciones</h1>
              <div className="section">
                <h4 className="subtitle">Curso FELE Intensivo y ELE+</h4>
                <p className="c-text-18">Pago de cursos, cancelaciones y reembolsos</p>
              </div>
              <div className="section">
                <p className="c-text-18">
                  <strong>FELE Intensivo</strong>
                  <br />
                  <br />
                  1. Una vez sea aceptado/a en el curso, se requerirá un depósito de 350 € para
                  reservar su plaza. Solo se le aceptará en el curso una vez que recibamos su
                  depósito.
                  <br />
                  <br />
                  2. El depósito de 350€ no es reembolsable.
                  <br />
                  <br />
                  <strong>ELE+</strong>
                  <br />
                  <br />
                  3. Se requiere el abono del importe total para reservar su plaza.
                  <br />
                  <br />
                  4. En el caso de cancelación, se le devolverá el importe del curso menos 150€.
                  <br />
                  <br />
                  5. Nos reservamos el derecho de cancelar su solicitud por falta de pago.
                  <br />
                  <br />
                  6. El saldo total del precio del curso debe pagarse 7 días antes de la fecha de
                  inicio del curso. Si el saldo pendiente no se paga dentro de este período de
                  tiempo, su plaza en el curso estará disponible para otros solicitantes y, si el
                  curso alcanza su plena capacidad, su plaza se perderá. Su depósito se puede
                  transferir a una fecha de inicio alternativa de otro curso.
                  <br />
                  <br />
                  7. Si cancela o pospone su curso:
                  <br />
                  <br />
                  <ul className="list">
                    <li>
                      Aviso con más de 4 semanas: no recibirá ninguna penalización y su depósito y
                      las tarifas adicionales pagadas del curso serán válidas para un curso futuro
                      dentro de los 12 meses siguientes; si desea cancelar completamente, tendrá
                      derecho a un reembolso completo menos su depósito.
                    </li>
                    <li>
                      {' '}
                      Con menos de 4 semanas de antelación: su depósito y las tarifas adicionales
                      pagadas del curso serán válidas para un curso futuro dentro de los 12 meses.
                      Si desea cancelar totalmente, se le reembolsará el 50% de las tarifas del
                      curso pagadas menos su depósito. El punto de corte es a las 17.00, hora
                      española, el jueves anterior a la fecha de inicio del curso que siempre es un
                      lunes.
                    </li>
                    <li>
                      Una vez que el curso haya comenzado, no se ofrecerán reembolsos ni
                      aplazamientos.
                    </li>
                    <li>
                      En el caso de reembolsos, se le devolverán las tarifas adeudadas dentro de los
                      90 días posteriores a la solicitud y confirmación del reembolso.
                    </li>
                  </ul>
                  <br />
                  8. ELE Barcelona se reserva el derecho de cancelar un curso que no cumpla con el
                  número mínimo de estudiantes. En caso de cancelación por parte de ELE Barcelona de
                  un curso que aún no ha comenzado, se reembolsará al alumno el importe total de la
                  reserva o cualquier otro pago que se haya realizado por adelantado. En su caso,
                  ELE Barcelona dará al alumno la oportunidad de realizar el curso en un formato
                  diferente, ya sea online o presencial.
                  <br />
                  <br />
                  9. Se aplica una tarifa de 70€ a las evaluaciones remitidas (evaluaciones
                  fallidas) que se reenvían.
                  <br />
                  <br />
                  10. ELE Barcelona se reserva el derecho de usar su propia discreción en
                  situaciones y puede hacer excepciones en una situación caso por caso.
                  <br />
                  <br />
                  11. Cuando se otorgue un reembolso, cualquier cargo bancario relacionado con
                  transferencias y movimientos del tipo de cambio se deducirá de la cantidad
                  reembolsable adeudada.
                  <br />
                  <br />
                  12. Sólo para cursos en línea: los estudiantes deben declarar que tienen los
                  recursos y el entorno adecuados para realizar un curso en línea. Esto incluye el
                  hardware apropiado (computadora portátil / computadora) que tenga suficientes
                  capacidades de video y sonido (altavoz y micrófono) junto con suficiente conexión
                  a internet. También se requiere que los estudiantes estén en un espacio tranquilo
                  y bien iluminado.
                </p>
              </div>
              <div className="section">
                <h4 className="subtitle">Visas de estudiantes</h4>
                <p className="c-text-18">
                  <ol>
                    <li>
                      Una vez aceptado en el curso, se requerirá un depósito de 350 € para reservar
                      su plaza.
                    </li>
                    <li>Los depósitos no son reembolsables.</li>
                    <li>
                      {' '}
                      El saldo total del curso debe pagarse por adelantado antes de la cita para la
                      visa, para poder enviarle la carta de inscripción al curso correspondiente y
                      el comprobante de pago.
                    </li>
                    <li>
                      {' '}
                      Debe notificar con al menos 3 semanas de anticipación su cita de visa junto
                      con el pago completo, para permitir el tiempo adecuado para preparar la
                      documentación necesaria.
                    </li>
                    <li>
                      {' '}
                      Los documentos de la visa se enviarán de forma gratuita, por correo ordinario
                      y certificado.
                    </li>
                    <li>
                      {' '}
                      Si necesita el envío expreso de la documentación de la visa, debe pagar las
                      tarifas de envío de estos documentos por adelantado. Suele ser de 60 a 70€ y
                      la carta se enviará por mensajería DHL.
                    </li>
                    <li>
                      {' '}
                      Si una visa es rechazada debido a un error por parte del estudiante, el
                      estudiante tendrá derecho a un reembolso menos el depósito del curso. Se
                      requerirá prueba de rechazo para procesar el reembolso.
                    </li>
                    <li>
                      {' '}
                      Si una visa es rechazada por error por parte de ELE Barcelona, el estudiante
                      tendrá derecho a un reembolso completo, incluido el depósito. Se requerirá
                      prueba de rechazo para procesar el reembolso.
                    </li>
                    <li>
                      Si se procesa y aprueba una visa de estudiante, no se emitirán reembolsos.
                    </li>
                    <li>
                      {' '}
                      Renovaciones de visa: Si hay un retraso en la aprobación de su visa y su visa
                      es rechazada, aún debe pagar el curso en el tiempo intermitente. Por ejemplo,
                      si solicita una renovación en enero pero es rechazada en junio, debe pagar el
                      saldo del curso de enero a junio. Solo se otorgará un reembolso a partir del
                      momento posterior a la fecha de rechazo.
                    </li>
                    <li>
                      {' '}
                      Si un estudiante cancela su inscripción en cualquier momento antes de comenzar
                      el curso, no se reembolsará el depósito.
                    </li>
                    <li>
                      {' '}
                      En el caso de reembolsos, se le devolverán las tarifas adeudadas dentro de los
                      90 días posteriores a la solicitud y confirmación del reembolso.
                    </li>
                    <li>
                      {' '}
                      Los cursos pueden cambiar de modalidad de presencial a en línea a discreción
                      de la escuela.
                    </li>
                    <li>
                      {' '}
                      Durante una visa de estudiante de 12 meses, tendrá 4 semanas de vacaciones de
                      verano en agosto, 3 semanas de vacaciones en Navidad y 1 semana de vacaciones
                      en Semana Santa. Estos tiempos se consideran festivos y no se recuperarán.
                    </li>
                    <li>Si una clase cae en un día festivo, la clase no se recuperará.</li>
                    <li>
                      Las clases perdidas debido a la ausencia de un estudiante no se pueden
                      recuperar.
                    </li>
                    <li>
                      Si el número de alumnos de un grupo se reduce a menos de 4, ELE Barcelona se
                      reserva el derecho a realizar ajustes (resetear tiempo, horario reducido,
                      integrarse en otro grupo).
                    </li>
                    <li>
                      La escuela puede sustituir a los profesores que imparten formación como mejor
                      le convenga.
                    </li>
                  </ol>
                </p>
              </div>
              <div className="section">
                <h2 className="subtitle">General</h2>
                <p className="c-text-18">
                  <ol>
                    <li>
                      ELE Barcelona opera en una academia de idiomas y espera una buena conducta. Se
                      espera que los alumnos respeten la academia, a su personal y a otros
                      estudiantes.
                    </li>
                    <li>
                      ELE Barcelona tiene el derecho de retirar a un alumno del curso y de la
                      academia como resultado de un comportamiento inaceptable que afecte al
                      personal y a los estudiantes. El alumno no recibirá ningún reembolso de las
                      tasas del curso si se elimina del curso.
                    </li>
                    <li>
                      Si se pierde alguna de las sesiones del curso debido a problemas de salud,
                      deberá proporcionar una nota del médico.
                    </li>
                    <li>
                      Si pierde más de 2 días del curso, puede perder su plaza en el curso y tendrá
                      que pagar una tarifa administrativa para recuperar el tiempo perdido en un
                      curso futuro.
                    </li>
                    <li>
                      ELE Barcelona no será responsable de la finalización no satisfactoria del
                      curso y no ofrecerá reembolsos por cursos incompletos y utilizará su
                      discreción, caso por caso, para tener la oportunidad de volver a asistir al
                      curso.
                    </li>
                    <li>
                      El certificado del curso ELE Barcelona estará disponible un mes después de la
                      finalización del curso.
                    </li>
                    <li>
                      Puede recoger su certificado en la escuela o se lo enviamos gratuitamente por
                      correo postal. Guardaremos el certificado hasta 2 años, después de lo cual
                      deberá pagar una tarifa de 25€ para obtener una copia impresa de reemplazo.
                    </li>
                    <li>
                      ELE Barcelona no se hace responsable de la pérdida o robo de efectos
                      personales.
                    </li>
                    <li>
                      ELE Barcelona se reserva el derecho de actualizar y realizar cambios en sus
                      Términos y Condiciones según lo considere oportuno.
                    </li>
                  </ol>
                </p>
              </div>

              <div className="section">
                <h2 className="subtitle">Covid19 y medidas sanitarias</h2>
                <p className="c-text-18">
                  <ul className="list">
                    <li>
                      ELE Barcelona sigue las medidas de seguridad y salud recomendadas por el
                      gobierno, por lo que se reserva el derecho de ajustar el número máximo de
                      alumnos permitidos en la escuela, reestructurar grupos y clases según
                      corresponda, así como cancelarlos en caso de riesgo de contagio.
                    </li>
                    <li>
                      En caso de cierre de una escuela, ELE Barcelona ofrecerá clases online como
                      alternativa.
                    </li>
                    <li>
                      En caso de ser necesario, ELE Barcelona se reserva el derecho a ofrecer un
                      servicio mínimo, como un horario reducido o cambio de profesor, según se
                      requiera.
                    </li>
                    <li>
                      ELE Barcelona se reserva el derecho a no realizar devoluciones en caso de
                      cancelaciones por parte del alumno.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default Terminos;
